import {
  Cpu,
  CheckSquare,
  User,
  Calendar,
  Users,
  Clipboard,
  Settings,
  Grid,
  Flag,
  Book,
  UserPlus,
  BookOpen,
  Sliders,
  Codesandbox,
  Copy,
  Clock,
  Monitor,
  Code,
  Briefcase,

} from 'react-feather';
import NavRoutes from '../../common/NavRoutes';

export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <Grid size={20} />,
    navLink: NavRoutes.dashboard,
    action: 'read',
    resource: 'dashboard',
  },
  {
    id: 'holidays',
    title: 'Holidays',
    icon: <Calendar size={20} />,
    navLink: NavRoutes.holidays,
    action: 'read',
    resource: 'holidays',
  },
  {
    id: 'timsheet',
    title: 'Timesheet',
    icon: <CheckSquare size={20} />,
    navLink: NavRoutes.todaysEntry,
    action: 'read',
    resource: 'timeSheet',
  },
  {
    id: 'timsheetsummary',
    title: 'Timesheet Summary',
    icon: <Clock size={20} />,
    navLink: NavRoutes.timesheetSummary,
    action: 'read',
    resource: 'timesheetSummary',
  },
  {
    id: 'reviewTimeSheet',
    title: 'Review Employee Timesheet',
    icon: <Monitor size={20} />,
    navLink: NavRoutes.reviewTimesheet,
    action: 'read',
    resource: 'reviewTimesheet',
  },
  {
    id: 'profile',
    title: 'My Profile',
    icon: <User size={20} />,
    navLink: NavRoutes.profile,
    action: 'read',
    resource: 'profile',
  },
  {
    id: 'employee',
    title: 'Employee Records',
    icon: <Users size={20} />,
    navLink: NavRoutes.employee,
    action: 'read',
    resource: 'employee',
  },
  {
    id: 'teams',
    title: 'Teams',
    icon: <Clipboard size={20} />,
    navLink: NavRoutes.teams,
    action: 'read',
    resource: 'teams',
  },
  {
    id: 'timeMachine',
    title: 'Time Machine',
    icon: <Settings size={20} />,
    navLink: NavRoutes.timemachine,
    action: 'read',
    resource: 'timeMachine',
  },
  {
    id: 'project',
    title: 'Projects',
    icon: <Cpu size={20} />,
    navLink: NavRoutes.projects,
    action: 'read',
    resource: 'projects',
  },
  {
    id: 'company',
    title: 'Company',
    icon: <Cpu size={20} />,
    action: 'read',
    resource: 'manageCompanies',
    navLink: NavRoutes.manageCompany,
  },
  {
    id: 'rolesandpermissions',
    title: 'Roles & Permissions',
    icon: <Flag size={20} />,
    navLink: NavRoutes.rolesandpermissions,
    action: 'read',
    resource: 'rolesandpermissions',
  },
  {
    id: 'courses',
    title: 'Courses',
    icon: <Book size={20} />,
    navLink: NavRoutes.courses,
    action: 'read',
    resource: 'courses',
  },
  {
    id: 'clients',
    title: 'Customers',
    icon: <BookOpen size={20} />,
    navLink: NavRoutes.clients,
    action: 'read',
    resource: 'clients',
  },
  {
    id: 'partners',
    title: 'Partners',
    icon: <UserPlus size={20} />,
    navLink: NavRoutes.partners,
    action: 'read',
    resource: 'partners',
  },
  {
    id: 'Positions',
    title: 'Positions',
    icon: <Flag size={20} />,
    navLink: NavRoutes.positions,
    action: 'read',
    resource: 'positions',
  },
  {
    id: 'Work Categories',
    title: 'Work Categories',
    icon: <Sliders size={20} />,
    navLink: NavRoutes.workCategories,
    action: 'read',
    resource: 'workCategories',
  },

  {
    id: 'Work Classifications',
    title: 'Work Classifications',
    icon: <Copy size={20} />,
    navLink: NavRoutes.workClassification,
    action: 'read',
    resource: 'workClassifications',
  },
  {
    id: 'Work Codes',
    title: 'Work Codes',
    icon: <Codesandbox size={20} />,
    navLink: NavRoutes.workCodes,
    action: 'read',
    resource: 'workCodes',
  },

  {
    id: 'Task',
    title: 'Task',
    icon: <Briefcase size={20} />,
    navLink: NavRoutes.task,
    action: 'read',
    resource: 'task',
  },
  {
    id: 'TrainingCenter',
    title: 'TrainingCenter',
    icon: <Code size={20} />,
    navLink: NavRoutes.trainingCenter,
    action: 'read',
    resource: 'task',
  },
  {
    id: 'organizationChart',
    title: 'Organizational Chart',
    icon: <UserPlus size={20} />,
    navLink: NavRoutes.heirarchyView,
    action: 'read',
    resource: 'profile',
  },
];
